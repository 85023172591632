// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.75 8a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0m6.108 3.794a4.75 4.75 0 1 0-5.717 0A7.75 7.75 0 0 0 4.25 19v1a.75.75 0 0 0 1.5 0v-1a6.25 6.25 0 1 1 12.5 0v1a.75.75 0 0 0 1.5 0v-1a7.75 7.75 0 0 0-4.892-7.206"
      clipRule="evenodd"
    />
  </svg>
);
export const UserIcon = forwardRef(SvgUser);
